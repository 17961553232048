import { getKindeSession } from "@kinde-oss/kinde-remix-sdk";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useNavigate } from "@remix-run/react";
import {
  AppProvider,
  BlockStack,
  Button,
  Card,
  InlineStack,
  Text,
} from "@shopify/polaris";
import { useCallback, useState } from "react";
import styles from "./style.css?url";

export const meta: MetaFunction = () => [
  {
    title: "Login - Store Locator",
  },
];

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  const { getUser, headers } = await getKindeSession(request);
  const user = await getUser();
  if (user) {
    throw redirect("/dashboard", {
      headers,
    });
  }

  return json(
    { title: "Store Locator" },
    {
      headers,
    },
  );
};

export default function Landing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onLogin = useCallback(() => {
    setLoading(true);
    navigate("/kinde-auth/login");
  }, [navigate]);
  const onRegister = useCallback(() => {
    setLoading(true);
    navigate("/kinde-auth/register");
  }, [navigate]);

  return (
    <AppProvider i18n={{}}>
      <div className="bg">
        <div className="container">
          <div className="wrapper">
            <Card padding="800">
              <BlockStack gap="500">
                <BlockStack gap="200">
                  <Text fontWeight="bold" variant="headingXl" as="h2">
                    Shoppy Store Locator
                  </Text>
                </BlockStack>

                <BlockStack gap="200">
                  <Button variant="primary" onClick={onLogin} loading={loading}>
                    Login
                  </Button>
                  <Button onClick={onRegister} loading={loading}>
                    Register
                  </Button>
                </BlockStack>

                <InlineStack gap="200">
                  <Button variant="plain">Help</Button>
                  <Button variant="plain">Privacy</Button>
                  <Button variant="plain">Terms</Button>
                </InlineStack>
              </BlockStack>
            </Card>
          </div>
        </div>
      </div>
    </AppProvider>
  );
}
